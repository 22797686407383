<template>
  <div class="tags">
    <van-nav-bar
      title="单个用户vip审批"
      right-text="列表"
      v-if="hasRole"
      @click-right="onClickRight"
    />
    <van-cell-group>
      <van-field
        placeholder="请输入账号"
        bind:change="onChange"
        v-model="account"
        label="账号"
        :maxlength="11"
        :readonly="true"
      />
      <van-field
        placeholder="请输入姓名"
        bind:change="onChange"
        v-model="nickName"
        label="姓名"
        :readonly="true"
      />
      <van-field
        placeholder="请输入充值金额"
        bind:change="onChange"
        v-model="money"
        label="充值金额"
        :readonly="true"
      />
      <!--时间计算组件-->
      <van-field
        placeholder="会员到期日"
        v-model="memberEndTime"
        label="会员到期日"
        :readonly="true"
      />
      <!--充值通道组件-->
      <van-field v-model="payChannel" label="充值渠道" :readonly="true" />
      <!--职业组件-->
      <van-field v-model="jobType" label="职业" :readonly="true" />
      <!--文件上传组件-->
      <van-uploader :file-list="fileList" />
      <!--律所组件-->
      <van-field
        placeholder="律所"
        v-model="lawfirmValue"
        label="律所"
        :readonly="true"
      />
      <!--选择客服-->
      <van-field
        placeholder="客服"
        v-model="cusValue"
        label="客服"
        :readonly="true"
      />
      <van-field
        placeholder="请输入通过/驳回理由"
        type="textarea"
        v-model="remark"
        label="通过/驳回理由"
      />
    </van-cell-group>
    <div class="footer">
      <van-button
        type="default"
        size="normal "
        style="float: left; width: 50%"
        :square="true"
        @click="reject()"
        >驳回</van-button
      >
      <van-button
        type="info"
        size="normal "
        style="width: 50%"
        :square="true"
        @click="approve()"
        >通过</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [{ url: "", name: "充值图片" }], //充值图片
      account: "", //账号
      nickName: "", //姓名
      userId: 0,
      jobType: "律师", //用户角色
      applyMonth: 0, //充值时间
      money: "", //充值金额
      memberEndTime: "", //会员到期时间
      payChannel: "", //充值渠道
      imageUrl: "", //充值图片
      approveUser: 0, //审批人
      lawfirmValue: "", //律所信息
      remark: "", //申请理由
      memberStartTime: "", //会员开始时间
      lawfirmName: "", //律所名称
      cusValue: "", //选中的客服
      id: 0, //申请会员的id
      hasRole: false,
      logId: "", //日志id
    };
  },
  methods: {
    //头部跳转
    onClickRight() {
      this.$router.push("/vipList");
    },
    //根据手机号查询用户
    queryUser() {
      //查询会员信息
      this.$axios
        .get(`${this.$base}/admin/member/apply/list?account=${this.account}`)
        .then((res) => {
          if (res) {
            this.account = res[0].account;
            this.nickName = res[0].nickname;
            this.money = res[0].rechargeMoney;
            this.memberEndTime = res[0].memberEndTime;
            this.payChannel = res[0].rechargeChannel;
            this.jobType = res[0].jobType;
            this.fileList[0].url = res[0].rechargeImgUrl;
            this.lawfirmValue = res[0].lawFirmName;
            this.cusValue = res[0].name;
            this.userId = res[0].applyUserId;
            this.id = res[0].id;
            // eslint-disable-next-line no-empty
          } else {
            this.$toast("暂无更多数据");
          }
        });
    },
    //通过
    approve() {
      this.$axios
        .get(
          `${this.$base}/admin/member/apply/agree?id=${this.id}&userId=${this.approveUser}&remark=${this.remark}&logId=${this.logId}`
        )
        .then((res) => {
          if (res.code === 200) {
            this.$toast("审批成功!");
          } else {
            this.$toast(res.message);
          }
        });
    },
    //驳回
    reject() {
      this.$axios
        .get(
          `${this.$base}/admin/member/apply/reject?id=${this.id}&userId=${this.approveUser}&remark=${this.remark}&logId=${this.logId}`
        )
        .then((res) => {
          if (res.code === 200) {
            this.$toast("驳回成功!");
          } else {
            this.$toast(res.message);
          }
        });
    },
  },
  mounted() {
    this.queryUser();
  },
  created() {
    this.account = this.$route.query.phone;
    this.approveUser = this.$route.query.userId;
    this.hasRole = this.$route.query.hasRole;
    this.logId = this.$route.query.logId;
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
